import axios from 'axios';
import { handleResponse, handleError } from '../utilities/Response';
import { apiProvider } from '../utilities/Provider';

export class ApiLogin {
    constructor() {
        this.login = async (model) =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/loginByApiGetTokenForReact`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.logoff = async () =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/account/logoff`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
        
        this.sendPassword = async (model) =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/account/sendpassword`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.registration = async (model) =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/account/registration`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.activate = async (model) =>{       
            return await axios
                .post(`${apiProvider.BASE_URL}/api/account/activate`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.getCurrentUser = async () =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/user/getCurrentUser`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.getNotificationUser = async () =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/notification/readNotification`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.readNotifcation = async (id) =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/notification/readNotificationById?id=`+id, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.saveCurrentUser = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/user/add`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.isHaveToPaid = async () =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/account/isHaveToPaid`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
    }
}

export const apiLogin = new ApiLogin({});