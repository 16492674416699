import React, { useState, useEffect } from 'react';
import { Button,Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {apiDocument} from '../../services/api/documents/Document';

const PayLoadComponent = (props) => {
    const { register,  getValues,handleSubmit, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
            
    }, []);

    const onSubmit = async (data) => {
        let companyName= getValues("companyName");
        let nip= getValues("nip");
        let street= getValues("street");
        let number1= getValues("number1");
        let number2= getValues("number2");
        let postalCode= getValues("postalCode");
        let city= getValues("city");
        let country= getValues("country");
        let result = await apiDocument.pay({companyName: companyName, companyNip: nip, companyStreet:street, companyNumber: number1, companyNumber2: number2, companyPostalCode: postalCode, companyCity: city, companyCountry: country});
        if(result.redirectUrl)
        {
            debugger;
            window.location.href=result.redirectUrl;
        }
    }

    return (
        <div>
        {props.isOpenPayLoad && 
            <form onSubmit={handleSubmit(onSubmit)}>
            <div class="popup-dialog" id="payload">
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={11}>
                                <h5>Płatności</h5>
                            </Grid>
                            <Grid xs={1}><a onClick={(event)=>{props.setIsOpenPayLoad(false);props.setIsBlur(false);}} class="x"></a></Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="payLoad-info">
                        <p><i class='invoice'></i>&nbsp;&nbsp;&nbsp;Dane do faktury</p>
                    </Grid>
                    <div class="border-top"></div>
                    <Grid container spacing={2}>
                        <Grid item xs={5}><label class="MuiFormLabel-root">Nazwa firmy</label>
                            <TextField autoComplete='off' {...register("companyName", { required: true })} placeholder="Nazwa firmy"/>
                        </Grid>
                        <Grid item xs={5}><label class="MuiFormLabel-root">NIP</label>
                            <TextField autoComplete='off' {...register("nip", { required: true })} placeholder="NIP"/>
                        </Grid>
                    </Grid>
                    <h4>Adres</h4>
                    <div class="border-top"></div>
                    <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Ulica</label>
                            <TextField autoComplete='off' {...register("street", { required: true })}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr domu</label>
                            <TextField autoComplete='off' {...register("number1", { required: true })}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr lokalu</label>
                            <TextField autoComplete='off' {...register("number2")} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kod pocztowy</label>
                            <TextField autoComplete='off' {...register("postalCode", { required: true })}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Miejscowośc</label>
                            <TextField autoComplete='off' {...register("city", { required: true })}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kraj</label>
                            <TextField autoComplete='off' {...register("country", { required: true })}/>
                        </Grid>
                    </Grid>
                    
                    <div class="border-top mt-10"></div>
                    <Grid container className="popup-dialog-info" >
                        <Grid item xs={3}>
                            <span>Nazwa usługodawcy</span>
                            <p>SignSafe Sp.z.o.o</p>
                        </Grid>
                        <Grid item xs={3}>
                            <span>Data rozpoczęcia płatności</span>
                            <p>16.04.2023</p>
                        </Grid>
                        <Grid item xs={3}>
                            <span>Okres rozliczeniowy</span>
                            <p>Mięsieczny</p>
                        </Grid>
                        <Grid item xs={3}>
                            <span>Kwota płatności</span>
                            <p>24.60 zł(20.00 zł + VAT)</p>
                        </Grid>
                    </Grid> 
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <p class="checkbox-popup"><input type="checkbox"/>&nbsp;Potwierdzam, że przeczytałem i zrozumiałem wszystkie warunki umowy oraz politykę prywatności.</p>
                        </Grid>
                    </Grid>
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <Button class="MuiButtonBase-root MuiButton-root MuiButton-text button" tabindex="0" type="submit" id="tokenizeButton" style={{height:'38px !important'}}>
                                <span class="MuiButton-label" id="save-info">Przejdź do płatności</span><span class="MuiTouchRipple-root"></span>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <div class="info-label">
                                <p>Płatność realizowana za pomocą serwisu TPay. </p>
                                <p>Możesz anulować subskrypcję w dowolnym momencie.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            </form>
        }
        </div>
    );
}

export default PayLoadComponent;